import { useEffect, useState } from "react";
import { Loading } from "../../layout/Loading";
import { viewMainCores } from "../../../util/api";
import { useParams } from "react-router-dom";
import MainCore from "./MainCore";

const ViewThreeValue = (props) => {

  const { whatIDo } = props;


  return (

    <div className="card">
      <div className="card-header border-0 pb-0">
        <h5 className="card-title">What I Do</h5>
        <p>{whatIDo?.coreDescription || ""} </p>
      </div>
      <div className="card-body">

       <MainCore core={whatIDo} />
       
      </div>
    </div>);
}

export default ViewThreeValue;