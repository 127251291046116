import { useParams } from "react-router-dom";
import { LoadingText } from "../../layout/Loading";
import Notice from "./Notice";

const ViewCover = (props) => {

  const { profile } = props;
  const { profileId } = useParams();
  let profileUrl = "";

  if (profileId) {
    profileUrl = '/u/' + profileId;
  } else {
    profileUrl = "https://www.rimsan.me"
  }

  let hasLogged = false;
  if (localStorage.getItem("token")) {
    hasLogged = true;
  }

  return (<>

    <Notice />

    <div className="card">

      <div className="h-100px rounded-top" style={{ backgroundImage: `url(https://rimsan.me/images/back.jpg)`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>

      <div className="card-body py-0">
        <div className="d-sm-flex align-items-start text-center text-sm-start">
          <div>

            {(profile?.profilePicture !== "") ? (<div className="avatar avatar-xxl mt-n5 mb-3">
              <img className="avatar-img rounded-circle bg-primary border border-white border-3" src={profile?.profilePicture} alt="" />
            </div>) : <div className="avatar avatar-xxl mt-n5 mb-3 text-center">
              <div className="avatar-img rounded-circle bg-primary border border-white border-3">
                <span className="text-white position-absolute top-50 start-50 translate-middle fw-bold display-6">
                  {(profile?.profilePicture !== "") ? "" : profile?.firstName[0]}
                </span>
              </div>
            </div>}




          </div>
          <div className="ms-sm-4 mt-sm-3">

            <h1 className="mb-0 h5">{(profile?.firstName || "Loading...") + " " + (profile?.lastName || "")} &nbsp;<i className="bi bi-patch-check-fill text-success small"></i></h1>
            <p>      {profile?.smallDescription} </p>
          </div>

          <div className="d-flex mt-3 justify-content-center ms-sm-auto ">

            <div className="dropdown ">

              <button className="icon-md btn btn-light" type="button" id="profileAction2" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bi bi-three-dots"></i>
              </button>

              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="profileAction2">
                <li><a className="dropdown-item " href={profileUrl}> <i className="bi bi-bookmark fa-fw pe-2"></i>Share profile</a></li>
              {(hasLogged) ? <>
                  <li><hr className="dropdown-divider" /></li>
                  <li><a className="dropdown-item" href="/settings"> <i className="bi bi-gear fa-fw pe-2"></i>Profile settings</a></li>
                  <li><a className="dropdown-item" href="/logout"> <i className="bi bi-power fa-fw me-2"></i>Logout</a></li>
                  </> : ""}

              </ul>
            </div>
          </div>
        </div>

        <ul className="list-inline mb-0 text-center text-sm-start mt-3 mt-sm-0">
          <li className="list-inline-item"><i className="bi bi-briefcase me-1"></i> {profile?.currentPosition || ""}</li>
          <li className="list-inline-item"><i className="bi bi-geo-alt me-1"></i> {profile?.currentLocation || ""}</li>
          {/** <li className="list-inline-item"><i className="bi bi-calendar2-plus me-1"></i> Last Update { updatedAt || ""}</li> */}
        </ul>
      </div> 
      

      <div className="card-footer mt-3 pt-2 pb-0" dangerouslySetInnerHTML={{ __html: (`<p>${profile?.longDescription || LoadingText}</p>`) }}></div>

      {/** <a href="#!" role="button" className="btn btn-loader btn-primary-soft rounded-0" data-bs-toggle="button" aria-pressed="true">
						<span className="load-text border-0"> Read more </span>
						<div className="load-icon">
							<div className="spinner-grow spinner-grow-sm" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					</a> */}



    </div></>

  );
}

export default ViewCover;