import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import NoPage from './components/error/NoPage';
import Layout from './components/layout/Layout';
import App from './App';
import PortfolioDetails from './components/home/project/PortfolioDetails';
import AllProjects from './components/home/project/AllProjects';
import Settings from './components/home/settings/Settings';
import AllPost from './components/blog/AllPost';
import PostDetails from './components/blog/PostDetails';
import Footer from './components/footer/Footer';
import PrivacyPolicy from './components/privacy/PrivacyPolicy';
import ContactMe from './components/contact/ContactMe';
import VideoStream from './components/video/VideoStream';
import Login from './components/login/Login';
import Register from './components/signup/Register';
import Logout from './components/login/Logout';
import TermsService from './components/privacy/TermsService';


export default function Main() {

	const [siteTitle, setSiteTitle] = useState("Loading...");


	function hangleSiteTitle(title) {
		if (title.length > 32) {
			title = title.substring(0, 20) + "..."
		}
		setSiteTitle(title);
	}
	


	return (<BrowserRouter>
		<Routes>
			<Route
				path='/'
				element={
					<Layout siteTitle={siteTitle}>
						<App setSiteTitle={hangleSiteTitle} />
						<Footer />
					</Layout>
				} />

			<Route
				path='/u/about/:profileId'
				element={
					<Layout siteTitle={siteTitle}>
						<App setSiteTitle={hangleSiteTitle} />
						<Footer />
					</Layout>
				} />
			<Route
				path='/settings'
				element={
					<Layout siteTitle={siteTitle}>
						<Settings setSiteTitle={hangleSiteTitle} />
						<Footer />
					</Layout>
				} />
			<Route
				path='/portfolio/:id'
				element={
					<Layout siteTitle={siteTitle}>
						<PortfolioDetails setSiteTitle={hangleSiteTitle} />
						<Footer />
					</Layout>
				} />

			<Route
				path='/u/portfolio/:profileId/:id'
				element={
					<Layout siteTitle={siteTitle}>
						<PortfolioDetails setSiteTitle={hangleSiteTitle} />
					    <Footer />
					</Layout>
				} />


			<Route
				path='/portfolio'
				element={
					<Layout siteTitle="Portfolio">
						<AllProjects />
						<Footer />
					</Layout>
				} />


			<Route
				path='/u/portfolio/:profileId'
				element={
					<Layout siteTitle="Portfolio">
						<AllProjects />
					   <Footer />
					</Layout>
				} />


			<Route
				path='/blog'
				element={
					<Layout siteTitle="Blog">
						<AllPost />

					</Layout>
				} />

			<Route
				path='/u/blog/:profileId'
				element={
					<Layout siteTitle="Blog">
						<AllPost />

					</Layout>
				} />

			<Route
				path='/blog/:id'
				element={
					<Layout siteTitle={siteTitle}>
						<PostDetails setSiteTitle={hangleSiteTitle} />

					</Layout>
				} />

			<Route
				path='/u/blog/:profileId/:id'
				element={
					<Layout siteTitle={siteTitle}>
						<PostDetails setSiteTitle={hangleSiteTitle} />

					</Layout>
				} />

			<Route
				path='/privacy-policy'
				element={
					<Layout siteTitle={siteTitle}>
						<PrivacyPolicy setSiteTitle={hangleSiteTitle} />
					</Layout>
				}
			/>
			<Route
				path='/terms-of-Service'
				element={
					<Layout siteTitle={siteTitle}>
						<TermsService setSiteTitle={hangleSiteTitle} />
					</Layout>
				}
			/>

			<Route
				path='/contact'
				element={
					<Layout siteTitle={siteTitle}>
						<ContactMe setSiteTitle={hangleSiteTitle} />
						<Footer />
					</Layout>
				}
			/>

			<Route
				path='/u/contact/:profileId'
				element={
					<Layout siteTitle={siteTitle}>
						<ContactMe setSiteTitle={hangleSiteTitle} />
						<Footer/>
					</Layout>
				}
			/>

			<Route
				path='/video-stream'
				element={
					<Layout siteTitle={siteTitle}>
						<VideoStream setSiteTitle={hangleSiteTitle} />
					</Layout>
				}
			/>

			<Route
				path='/u/video-stream/:profileId'
				element={
					<Layout siteTitle={siteTitle}>
						<VideoStream setSiteTitle={hangleSiteTitle} />
					</Layout>
				}
			/>

			<Route
				path='/login'
				element={
					<Layout siteTitle={siteTitle}>
						<Login setSiteTitle={hangleSiteTitle} />
						<Footer />
					</Layout>
				}
			/>

			<Route
				path='/logout'
				element={
					<Layout siteTitle={siteTitle}>
						<Logout setSiteTitle={hangleSiteTitle} />
					</Layout>
				}
			/>

			<Route
				path='/sign-up'
				element={
					<Layout siteTitle={siteTitle}>
						<Register setSiteTitle={hangleSiteTitle} />
						<Footer />
					</Layout>
				}
			/>

			<Route
				path='*'
				element={<NoPage />}
			/>

		</Routes>
	</BrowserRouter>);
}