import { useParams } from "react-router-dom";

export default function Project({ project }) {

    let url = "";
    const { profileId } = useParams(); 

    if (profileId) {
        url = `/u/portfolio/${ project.profileId}/` + project._id; // + '?profileId=' + project.profileId;
    } else {
        url = '/portfolio/' + project._id + '?profileId=' + project.profileId;
    }

    return (<>
        <div className="card bg-transparent border-0">
            <div className="row g-3">
                <div className="col-2">
                    <img className="rounded" src={project.coverImage} alt="" style={{ display: '' }} />
                </div>
                <div className="col-10">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5><a href={url} className="btn-link stretched-link text-reset fw-bold">{project.title}</a></h5>
                        <div className="avatar avatar-sm mx-3">
                            <img className="avatar-img rounded-circle" src={project.thumb} alt="avatar" />
                        </div>
                    </div>
                    <p className="mt-0 mb-2"><a className="small text-secondary" href="#!"> <i className="bi bi-briefcase pe-1"></i> {project.company}</a></p>

                    <div className="d-none d-sm-inline-block">
                        <p className="mb-2">{project.summary}</p>
                        {project.tools.map((tool, i) => <span key={i} className="badge bg-success bg-opacity-10 text-success mb-2 fw-bold mx-1">{tool}</span>)}
                    </div>
                </div>
            </div>
            <hr className="my-4 mt-4" />
        </div>

    </>
    );
}