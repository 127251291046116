import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    contents: {},
    isLoading: false,
    error: null,
};

// First, create the thunk
export const fetchByUserId = createAsyncThunk('profile/fetchByUserId', async (userId) => {
    const response = await fetch("https://rimsan.me/endpoint/profiles.php?profileId=" + userId)
    return response.json();
}
)

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers(builder)  {
        builder.addCase(fetchByUserId.pending, (state) => {
            state.isLoading = true
        }).addCase(fetchByUserId.fulfilled, (state, action) => {
            state.isLoading = false
            state.contents = action.payload
        }).addCase(fetchByUserId.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        });
    }
});



export default profileSlice.reducer;