import { useEffect, useState } from "react";
import Post from "./Post";
import { viewPosts } from "../../util/api";
import { Loading } from "../layout/Loading";
import { useNavigate, useParams } from "react-router-dom";

export default function AllPost() {

    const [posts, setPosts] = useState({});
    const { profileId } = useParams();

    useEffect(() => {

        const loadPosts = async (profileId) => {
            const data = await viewPosts(profileId);
            setPosts(values => (data)); 
        }

        loadPosts(profileId);

    }, [ profileId ]);

    const navigate = useNavigate();

    const handleHistroy = () => { navigate(-1); }

    return (<main>
        <div className="container">
            <div className="row g-4">

                <div className="col-lg-8">
                    <div className="bg-mode p-4">
                        <h1 className="h4 mb-4">Latest blogs</h1>
                        <a className="btn btn-primary position-fixed end-0 bottom-0 me-5 mb-5 btn-back" href="#!" onClick={handleHistroy}> <i className="fa-solid fa-angle-left"></i> Back</a>
	


                        {posts?.contents?.map((post, index) => <Post profileId={profileId} post={post} key={index} />) || <Loading />}



                    </div>
                </div>


                <div className="col-lg-4">
                    <div className="row g-4">
                        

                        <div className="col-sm-6 col-lg-12">
                            <div className="card">

                                <div className="card-header pb-0 border-0">
                                    <h5 className="card-title mb-0">Recent post</h5>
                                </div>

                                <div className="card-body">
                                    {posts?.contents?.map((post, index) => {
                                        return <div key={index} className="mb-3">
                                            <h6 className="mb-0"><a href={(profileId)? '/u/blog/'+ profileId +"/"+ post._id : '/blog/' + post._id}>{post.title}</a></h6>
                                            {/**<small>2hr</small> */}
                                        </div>
                                    }) || <Loading />}


                                </div>  
 
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>);
}