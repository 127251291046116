import { useEffect } from "react";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy(props) {

    useEffect(() => { props.setSiteTitle("Privacy Policy for Rimsan.me"); }, []);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1)
    }

    return (<>
        <main>
            <div class="container">
                <div class="row g-4">

                    <div class="col-lg-8 mx-auto">

                        <div class="card">
                            <div class="card-header d-sm-flex justify-content-between ">
                                <h1 class="card-title h4 mb-0">Privacy &amp; policy</h1>
                                <a class="btn btn-primary-soft btn-sm" onClick={handleBack}> Back</a>
                            </div>
                            <div class="card-body">

                                <h1></h1>

                                <h5>Introduction</h5>
                                <p>Welcome to Rimsan.me. Your privacy is important to us. This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>

                                <h5>Information We Collect</h5>
                                <p><strong>Personal Data:</strong> Information such as your name, email address, and contact details that you voluntarily provide.</p>
                                <p><strong>Usage Data:</strong> Information about how you interact with our site, including IP address, browser type, and pages visited.</p>

                                <h5>Use of Your Information</h5>
                                <p>We may use the information we collect to:</p>
                                <ul>
                                    <li>Provide, operate, and maintain our website</li>
                                    <li>Improve, personalize, and expand our website</li>
                                    <li>Communicate with you, including for customer service and updates</li>
                                    <li>Process your transactions and manage your orders</li>
                                    <li>Understand and analyze how you use our website</li>
                                </ul>

                                <h5>Disclosure of Your Information</h5>
                                <p>We may share your information with:</p>
                                <ul>
                                    <li>Service providers who perform services for us</li>
                                    <li>Affiliates for operational purposes</li>
                                    <li>Legal authorities if required by law</li>
                                </ul>

                                <h5>Security of Your Information</h5>
                                <p>We use administrative, technical, and physical security measures to help protect your personal information. However, no security system is impenetrable.</p>

                                <h5>Your Data Protection Rights</h5>
                                <p>Depending on your location, you may have the right to:</p>
                                <ul>
                                    <li>Access, correct, or delete your personal information</li>
                                    <li>Object to or restrict processing of your data</li>
                                    <li>Withdraw consent where we rely on your consent to process your information</li>
                                </ul>

                                <h5>Changes to This Privacy Policy</h5>
                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                                <h5>Contact Us</h5>
                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at [rimsnet@gmail.com].</p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </>)
}