import { useEffect, useState } from "react"
import { viewProjects } from "../../../util/api";
import Project from "./Project";
import { Loading } from "../../layout/Loading";


export default function ViewFeaturedProjects({ title, description, profileId }) {


    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const loadProjects = async (profileId) => {
            const project = await viewProjects(profileId);
            setProjects(values => (project));
        }
        loadProjects(profileId);
        
    }, [profileId]);


    return <div className="card ">
        <div className="card-header border-0 pb-0" >
            <div className="d-sm-flex align-items-center justify-content-between border-0 pb-0">
                <h1 className="h4 mb-3">Featured Projects</h1>
                <a className="btn btn-primary-soft btn-sm mb-2" href="/portfolio"> View All Project</a>
            </div>
            <p>This section you can see the featured projects.</p>
        </div>

        <div className="card-body">
            <div className="row g-4">
                {projects?.contents?.map((data, index) => {
                    let featured = Number.parseInt(data.featured);
                    return (featured === 1) ? <Project project={data} key={index} /> : ""
                }) || <Loading />}

            </div>
        </div>
    </div>
}