import { configureStore } from "@reduxjs/toolkit";
import profileSlice from "./slice/profileSlice";
import userSlice from "./slice/userSlice";
import noticeSlice from "./slice/noticeSlice";

export const store = configureStore({
    devTools: true,
    reducer: {
        profile: profileSlice,
        users: userSlice,
        notice: noticeSlice
    }
})