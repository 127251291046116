import { useEffect, useState } from "react";
import { httpRegister } from "../../util/api";
import { useNavigate } from "react-router-dom";
import { LoadingWithoutSpace } from "../layout/Loading";
import GoogleButton from "../login/GoogleButton";

export default function Register(props) {

    const [inputs, setInputs] = useState({google:0});
    const [loading, setLoading] = useState("Sign me up");
    const [errorMessage, setErrorMessage] = useState(`We'll never share your email with anyone else.`);
    const [passwordType, setPasswordType] = useState({
        type: "password",
        icon: "fa-eye-slash"
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();


        const password = inputs.password;
        const confirmPassword = inputs.confirmPassword;

        if (password == confirmPassword) {

            setLoading(<LoadingWithoutSpace />);
            const register = await httpRegister(inputs);

            if (register.message == "success") {                
                const data = { profileId: register.profileId, token: register.jwt, message: register.message  }
                localStorage.setItem("token", JSON.stringify(data));
                navigate("/settings");
            } else {
                setErrorMessage(register.message);
                setLoading("Sign me up");
            }

        } else {
            setErrorMessage("Password mismatch error");
        }
    }

    const handlePasswordIcon = () => {
        if (passwordType.type == "password") {
            setPasswordType({type: "text", icon: "fa-eye"});
        } else {
            setPasswordType({type: "password", icon: "fa-eye-slash"});
        } 
    }

    useEffect(() => {
        props.setSiteTitle("Get a Free Account");
    }, [props])

    return (<main>
        <div className="container">
            <div className="row justify-content-center align-items-center  py-3">

                <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">

                    <div className="card card-body rounded-3 p-4 p-sm-5">
                        <div className="text-center">

                            <h1 className="mb-2">Sign up</h1>
                            <span className="d-block">Already have an account? <a href="login">Sign in here</a></span>
                            <center> <p className="mb-md-0 mb-xs-3 mt-3"><GoogleButton text="signup_with" /></p></center>

                        </div>

                        <form className="mt-4" onSubmit={handleSubmit}>

                            <div className="mb-3 input-group-lg">
                                <input type="email" className="form-control" placeholder="Enter email" name="username" onChange={handleChange} value={inputs.username || ""} />
                                <small>{errorMessage}</small>
                            </div>

                            <div className="mb-3 position-relative">

                                <div className="input-group input-group-lg">
                                    <input className="form-control fakepassword" type={passwordType.type} id="psw-input" placeholder="Enter new password" name="password" onChange={handleChange} value={inputs.password || ""} />
                                    <span className="input-group-text p-0">
                                        <i className={"fakepasswordicon fa-solid cursor-pointer p-2 w-40px " + passwordType.icon} onClick={handlePasswordIcon}></i>
                                    </span>
                                </div>

                           
                            </div>

                            <div className="mb-3 input-group-lg">
                                <input className="form-control" type="password" placeholder="Confirm password" name="confirmPassword" onChange={handleChange} value={inputs.confirmPassword || ""} />
                            </div>

                            <div className="mb-3 text-start">
                                <input type="checkbox" className="form-check-input" id="keepsingnedCheck" />
                                <label className="form-check-label" htmlFor="keepsingnedCheck"> &nbsp; Keep me signed in</label>
                            </div>

                            <div className="d-grid"><button type="submit" className="btn btn-lg btn-primary">{loading}</button></div>

                            <p className="mb-0 mt-3 text-center">©2022 <a target="_blank" href="https://www.rimsan.me/">Rimsan.Me.</a> All rights reserved</p>
                        </form>

                    </div>

                </div>
            </div>
        </div>


    </main>);
}