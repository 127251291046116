import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import Main from './Main';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { store } from './Learn/redux/store';
//import 'react-toastify/dist/ReactToastify.css';
//import App from './Learn/App';

const root = ReactDOM.createRoot(document.getElementById('root'));

//root.render(<App />)


root.render(<Provider store={store}>
  <React.StrictMode>
    <GoogleOAuthProvider clientId='11879444161-09985e90as4vdma50tgidvc9vlv38rt4.apps.googleusercontent.com'>
      <Main />
    </GoogleOAuthProvider>
  </React.StrictMode> </Provider>
); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
