import { useEffect } from "react";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";

export default function TermsService(props) {

    useEffect(() => { props.setSiteTitle("Terms of Service for Rimsan.me"); }, []);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1)
    }

    return (<>
        <main>
            <div class="container">
                <div class="row g-4">

                    <div class="col-lg-8 mx-auto">

                        <div class="card">
                            <div class="card-header d-sm-flex justify-content-between ">
                                <h1 class="card-title h4 mb-0">Terms of Service</h1>
                                <a class="btn btn-primary-soft btn-sm" onClick={handleBack}> Back</a>
                            </div>
                            <div class="card-body">


                                <h5>Introduction</h5>
                                <p>Welcome to Rimsan.me. By accessing or using our website, you agree to be bound by these Terms of Service. If you do not agree with any part of these terms, please do not use our website.</p>

                                <h5>1. Use of the Website</h5>
                                <ul>
                                    <li>You must be at least 18 years old to use this website.</li>
                                    <li>You agree to use the website for lawful purposes only and not to engage in any activity that disrupts or interferes with the website.</li>
                                </ul>

                                <h5>2. Portfolio and Blogs</h5>
                                <ul>
                                    <li>The content of portfolios and blogs on this site is for your general information and personal use only. It is subject to change without notice.</li>
                                    <li>Unauthorized use of the website may give rise to a claim for damages and/or be a criminal offense.</li>
                                </ul>

                                <h5>3. User-Generated Content (Messages)</h5>
                                <ul>
                                    <li>You may submit messages, feedback, or comments. By doing so, you grant Rimsan.me a non-exclusive, royalty-free license to use, distribute, and display such content.</li>
                                    <li>You agree not to post any content that is defamatory, infringing, or violates any laws.</li>
                                </ul>

                                <h5>4. Skills, Education, and Work Experiences</h5>
                                <p>Information regarding skills, education, and work experiences shared on this website is provided by users and is for informational purposes only. Rimsan.me does not verify the accuracy or authenticity of such information.</p>

                                <h5>5. Intellectual Property</h5>
                                <ul>
                                    <li>All content on this website, including text, graphics, logos, and images, is the property of Rimsan.me or its content suppliers and is protected by copyright laws.</li>
                                    <li>You may not reproduce, distribute, or create derivative works from any content without prior written consent.</li>
                                </ul>

                                <h5>6. Limitation of Liability</h5>
                                <p>Rimsan.me will not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the website.</p>
                                <p>We do not warrant that the website will be uninterrupted or error-free.</p>

                                <h5>7. Changes to Terms of Service</h5>
                                <p>We reserve the right to update or modify these terms at any time without prior notice. Your continued use of the website constitutes your acceptance of the new terms.</p>

                                <h5>Contact Us</h5>
                                <p>If you have any questions about these Terms of Service, please contact us at [rimsnet@gmail.com].</p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </>)
}