import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { LoadingWithoutSpace } from "./Loading";

import { useDispatch, useSelector } from "react-redux";
import { fetchByUserId } from "../../Learn/redux/slice/profileSlice";

export default function Layout(props) {

    const { pathname } = useLocation();
    let { profileId } = useParams();


    let loginObj = {
        path: "/login",
        text: "Login"
    }

    if (localStorage.getItem("token")) {
        loginObj.path = "/settings";
        loginObj.text = "Settings";
    }

    const strToken = localStorage.getItem("token");

    if (strToken) {
        let tokenJSON = JSON.parse(strToken);
        profileId = tokenJSON.profileId;
    }

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchByUserId(profileId));
    }, [profileId, dispatch]);


    const { profile } = useSelector((state) => state.profile.contents);
    //const isLoading = useSelector((state) => state.profile.isLoading)
    // const error = useSelector((state) => state.profile.error)


    return (<>
        <header className="navbar-light fixed-top header-static bg-mode">


            <nav className="navbar navbar-expand-lg">
                <div className="container">

                    <a className="navbar-brand" href={(profileId) ? `/u/about/${profileId}` : "/"}>
                        {(profile) ? (profileId) ? <><i className="bi bi-globe-americas"></i> {profile?.firstName + " " + profile?.lastName}</> : props.siteTitle : <LoadingWithoutSpace />}
                    </a>

                    <button className="navbar-toggler ms-auto icon-md btn btn-light p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-animation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>


                    <div className="collapse navbar-collapse" id="navbarCollapse">


                        {/*<div className="nav mt-3 mt-lg-0 flex-nowrap align-items-center px-4 px-lg-0">
            <div className="nav-item w-100">
              <form className="rounded position-relative">
                <input className="form-control ps-5 bg-light" type="search" placeholder="Search..." aria-label="Search" />
                <button className="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y" type="submit"><i className="bi bi-search fs-5"> </i></button>
              </form>
            </div>
          </div>*/}


                        <ul className="navbar-nav navbar-nav-scroll ms-auto">

                            <li className="nav-item">
                                <a className={(pathname.length === 1) ? "nav-link active" : " nav-link "} href="/">Home</a>
                            </li>

                            <li className="nav-item">
                                <a className={(pathname.includes("/u/about/")) ? "nav-link active" : " nav-link "} href={(profileId) ? `/u/about/${profileId}` : "/u/about/669151fa8aecbee3a8de23f7"}>About</a>
                            </li>

                            <li className="nav-item">
                                <a className={(pathname.includes("/portfolio")) ? "nav-link active" : " nav-link "} href={(profileId) ? `/u/portfolio/${profileId}` : "/portfolio"}>Portfolio</a>
                            </li>

                            <li className="nav-item">
                                <a className={(pathname.includes("/blog")) ? "nav-link active" : " nav-link "} href={(profileId) ? `/u/blog/${profileId}` : "/blog"}>Blog</a>
                            </li>

                            <li className="nav-item">
                                <a className={(pathname.includes("/video-stream")) ? "nav-link active" : " nav-link "} href={(profileId) ? `/u/video-stream/${profileId}` : "/video-stream"}>Video Stream</a>
                            </li>

                            <li className="nav-item">
                                <a className={(pathname.includes("/contact")) ? "nav-link active" : " nav-link "} href={(profileId) ? `/u/contact/${profileId}` : "/contact"}>Contact</a>
                            </li>

                            <li className="nav-item">
                                <a className={(pathname.includes("/login")) ? "nav-link active" : " nav-link "} href={loginObj.path}>{loginObj.text}</a>
                            </li>

                        </ul>
                    </div>

                    <ul className="nav flex-nowrap align-items-center ms-sm-3 list-unstyled ">
                        <li className="nav-item ms-2">
                            <a className="nav-link bg-light icon-md btn btn-light p-0" href={(profileId) ? `/u/contact/${profileId}` : "/contact"}>
                                <i className="bi bi-chat-left-text-fill fs-6"> </i>
                            </a>
                        </li>
                        <li className="nav-item ms-2 d-none">
                            <a className="nav-link bg-light icon-md btn btn-light p-0" href="settings">
                                <i className="bi bi-gear-fill fs-6"> </i>
                            </a>
                        </li>


                        {/**
                        <li className="nav-item ms-2 dropdown">
                            <a className="nav-link btn icon-md p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className="avatar-img rounded-2" src="https://rimsan.me/images/images-profile.png" alt="" />
                            </a>
                            <ul className="dropdown-menu dropdown-animation dropdown-menu-end pt-3 small me-md-n3" aria-labelledby="profileDropdown">

                                <li className="px-3">
                                    <div className="d-flex align-items-center position-relative">

                                        <div className="avatar me-3">
                                            <img className="avatar-img rounded-circle" src="https://rimsan.me/images/images-profile.png" alt="avatar" />
                                        </div>
                                        <div>
                                            <a className="h6 stretched-link" href="#">Lori Ferguson</a>
                                            <p className="small m-0">Web Developer</p>
                                        </div>
                                    </div>
                                    <a className="dropdown-item btn btn-primary-soft btn-sm my-2 text-center" href="my-profile.html">View profile</a>
                                </li>

                                <li><a className="dropdown-item" href="settings"><i className="bi bi-gear fa-fw me-2"></i>Settings &amp; Privacy</a></li>
                                <li>
                                    <a className="dropdown-item" href="https://support.webestica.com/" target="_blank">
                                        <i className="fa-fw bi bi-life-preserver me-2"></i>Support
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="docs/index.html" target="_blank">
                                        <i className="fa-fw bi bi-card-text me-2"></i>Documentation
                                    </a>
                                </li>
                                <li className="dropdown-divider"></li>
                                <li><a className="dropdown-item bg-danger-soft-hover" href="sign-in-advance.html"><i className="bi bi-power fa-fw me-2"></i>Sign Out</a></li>
                                <li> <hr className="dropdown-divider" /></li>

                                <li>
                                    <div className="modeswitch-item theme-icon-active d-flex justify-content-center gap-3 align-items-center p-2 pb-0">
                                        <span>Mode:</span>
                                        <button type="button" className="btn btn-modeswitch nav-link text-primary-hover mb-0 active" data-bs-theme-value="light" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Light">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sun fa-fw mode-switch" viewBox="0 0 16 16">
                                                <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path>
                                                <use href="#"></use>
                                            </svg>
                                        </button>
                                        <button type="button" className="btn btn-modeswitch nav-link text-primary-hover mb-0" data-bs-theme-value="dark" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Dark">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-moon-stars fa-fw mode-switch" viewBox="0 0 16 16">
                                                <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"></path>
                                                <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"></path>
                                                <use href="#"></use>
                                            </svg>
                                        </button>
                                        <button type="button" className="btn btn-modeswitch nav-link text-primary-hover mb-0" data-bs-theme-value="auto" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Auto">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-half fa-fw mode-switch" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
                                                <use href="#"></use>
                                            </svg>
                                        </button>
                                    </div>
                                </li>

                            </ul>
                        </li>
 */}

                    </ul>

                </div>
            </nav>

        </header>{props.children} </>)
}

