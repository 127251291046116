import { useEffect, useState } from "react";
import { viewSimilarProjects } from "../../../util/api";
import { Loading } from "../../layout/Loading";

export default function SimilarProject(props) {

    const { company, profileId } = props;

    const [projects, setProjects] = useState({});

    useEffect(() => {

        const load = async (profileId, company) => {
            const data = await viewSimilarProjects(profileId, company);
            setProjects(values => (data)); 
        }

        load(profileId, company);

    }, [company, profileId]); 

    return <div className="card-body">

        {projects?.contents?.map((project, index) => (
            <div key={index} className="row">
                <div className="d-sm-flex align-items-center mt-2">

                    <div className="avatar avatar-xl">
                        <a href="#!"><img className="avatar-img rounded border border-white border-3" src={project?.coverImage} alt="" /></a>
                    </div>

                    <div className="ms-sm-4 mt-2 mt-sm-0">

                        <h6 className="mb-1"><a href={'/portfolio/' + project._id + '?profileId=' + project.profileId}> {project?.title} </a></h6>
                        <ul className="nav nav-stack small">
                          
                            <li className="nav-item">
                                <i className="bi bi-geo-alt pe-1"></i> {project?.company}
                            </li>
                           
                        </ul>
                    </div>

                </div>
            </div>
        )) || <Loading />}

    </div>


}