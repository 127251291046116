import { useEffect } from "react";
import Welcome from "./components/home/dash/Welcome";

export default function App(props) {

  useEffect(()=>{
    props.setSiteTitle("Welcome");
  })

  return (<>
    <Welcome />
  </>);
}
