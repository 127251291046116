export default function MainCore({ core }) {

    return (<div className="row g-4">
        <div className="col-md-4">
            <div className="card h-100">
                <div className="card-header pb-0 border-0 d-flex align-items-center  ">

                    <h5 className="card-title mb-0 mt-0">{core?.coreOneTitle}</h5>
                </div>
                <div className="card-body">
                    <p>{core?.coreOne}</p>
                </div>

            </div>
        </div>

        <div className="col-md-4">
            <div className="card h-100">
                <div className="card-header pb-0 border-0 d-flex align-items-center  ">

                    <h5 className="card-title mb-0 mt-0">{core?.coreTwoTitle}</h5>
                </div>
                <div className="card-body">
                    <p>{core?.coreTwo}</p>
                </div>

            </div>
        </div>

        <div className="col-md-4">
            <div className="card h-100">
                <div className="card-header pb-0 border-0 d-flex align-items-center  ">

                    <h5 className="card-title mb-0 mt-0">{core?.coreThreeTitle}</h5>
                </div>
                <div className="card-body">
                    <p>{core?.coreThree}</p>
                </div>

            </div>
        </div>



    </div>)
}