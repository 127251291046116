import { useEffect, useState } from "react";
import { getProfile, httpUpdateAccountSettings } from "../../../util/api";
import { Loading, LoadingWithoutSpace } from "../../layout/Loading";
import { Bounce, toast, ToastContainer } from "react-toastify";

export default function AccountSettings() {

    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [savechanges, setSavechanges] = useState("Save changes");

    useEffect(() => {

        const load = async (id) => {
            const prof = await getProfile(id);
            setProfile(values => (prof.profile));
            setProfile(values => ({ ...values, username: prof.username }))
            setLoading(false);
        }

        const { profileId } = JSON.parse(localStorage.getItem("token"));
        load(profileId);

    }, []);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setProfile(values => ({ ...values, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        toast.success('Saving', {
            position: "bottom-center",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });

        setSavechanges(<LoadingWithoutSpace text="Loading..." />)

        const result = await httpUpdateAccountSettings(profile);
        if (result.message == "success") {

            setSavechanges("Save changes");
        }
    }

    return (<div className="card mb-4">

        <ToastContainer
            position="bottom-center"
            autoClose={800}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />

        <div className="card-header border-0 pb-0">
            <h1 className="h5 card-title">Account Settings</h1>
            <p className="mb-0"></p>
        </div>

        <div className="card-body">


            {(!loading) ? (<form className="row g-3" onSubmit={handleSubmit}>

                <div className="col-sm-6 col-lg-4">
                    <label className="form-label">First name</label>
                    <input type="text" className="form-control" placeholder="" name="firstName" onChange={handleChange} defaultValue={profile?.firstName} />
                </div>

                <div className="col-sm-6 col-lg-4">
                    <label className="form-label">Last name</label>
                    <input type="text" className="form-control" placeholder="" name="lastName" onChange={handleChange} defaultValue={profile?.lastName} />
                </div>

                <div className="col-sm-6 col-lg-4">
                    <label className="form-label">Current Location</label>
                    <input type="text" className="form-control" placeholder="" name="currentLocation" onChange={handleChange} defaultValue={profile?.currentLocation} />
                </div>

                <div className="col-sm-6 col-lg-4">
                    <label className="form-label">Current Position</label>
                    <input type="text" className="form-control" placeholder="" name="currentPosition" onChange={handleChange} defaultValue={profile?.currentPosition} />
                </div>

                <div className="col-12">
                    <label className="form-label">Tag Line</label>
                    <input type="text" className="form-control" placeholder="" name="smallDescription" onChange={handleChange} defaultValue={profile?.smallDescription} />
                </div>



                <div className="col-12 d-none">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultValue="" id="allowChecked" defaultChecked />
                        <label className="form-check-label" htmlFor="allowChecked">
                            Allow anyone to add you to their team
                        </label>
                    </div>
                </div>

                <div className="col-sm-6 d-none">
                    <label className="form-label">Phone number</label>
                    <input type="text" className="form-control" placeholder="" defaultValue="(678) 324-1251" />

                    <a className="btn btn-sm btn-dashed rounded mt-2" href="#!"> <i className="bi bi-plus-circle-dotted me-1"></i>Add new phone number</a>
                </div>

                <div className="col-sm-6">
                    <label className="form-label">Email</label>
                    <input type="text" className="form-control" disabled name="username" onChange={handleChange} defaultValue={profile?.username} />

                    <a className="btn btn-sm btn-dashed rounded mt-2 d-none" href="#!"> <i className="bi bi-plus-circle-dotted me-1"></i>Add new email address</a>
                </div>

                <div className="col-12">
                    <label className="form-label">Overview</label>
                    <textarea className="form-control" rows="10" placeholder="Description (Required)" name="longDescription" onChange={handleChange} defaultValue={profile?.longDescription}></textarea>
                    <small>Character limit: 300</small>
                </div>

                <div className="col-12 text-end">
                    <button type="submit" className="btn btn-sm btn-primary mb-0">{savechanges}</button>
                </div>
            </form>) : <Loading />}

        </div>

    </div>);
}