import { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import { viewAchievement } from "../../../util/api";
import { useParams } from "react-router-dom";
import { Loading } from "../../layout/Loading";


export default function Achievement() {

    const [achievement, setAchievement] = useState({});
    const { profileId } = useParams();
    const [images, setImages] = useState([]);

    useEffect(() => {
        const load = async () => {
            const achieve = await viewAchievement(profileId);
            setAchievement(achieve);

            if ('contents' in achieve) {
                setImages(JSON.parse(achieve.contents));
            }
        }

        load();
    }, [profileId])



    return (<div className={('description' in achievement) ? "card" : "card d-none"}>

        <div className="card-header border-0 pb-0">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">

                    <div>
                        <h6 className="card-title mb-0"> <a href="!#">Recent Key Achievements</a></h6>
                        <div className="nav nav-divider">
                            <p className="nav-item mb-0 small d-none">Mohamed Rimsan</p>
                            <span
                                className="nav-item small d-none"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="Public"
                                data-bs-original-title="Public">
                                <i className="bi bi-globe"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="dropdown d-none">
                    <a href="!#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction3" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-three-dots"></i>
                    </a>

                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction3">
                        <li><a className="dropdown-item" href="!#"> <i className="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                        <li><a className="dropdown-item" href="!#"> <i className="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                        <li><a className="dropdown-item" href="!#"> <i className="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                        <li><a className="dropdown-item" href="!#"> <i className="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" href="!#"> <i className="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                    </ul>
                </div>

            </div>
        </div>

        <div className="card-body">

            {('description' in achievement) ? <><p dangerouslySetInnerHTML={{ __html: achievement?.description }}></p>
                <Gallery images={images} /></> : <Loading />}

        </div>



    </div>);
}