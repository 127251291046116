const URL = 'https://rimsan.me/endpoint'

export async function getPosts() {
    const response = await fetch(`${URL}/posts`);
    return response.json();
}

export async function getPost(id) {
    console.log(id)
    const response = await fetch('http://localhost:3001/' + id);
    return response.json();
}

export async function getProfile(id) {
    const response = await fetch(`${URL}/profiles.php${ (id)?'?profileId=' + id:'' }`);
    return response.json();
}

export async function updateProfile(body) {
   const response = await fetch(`${URL}/profiles.php`, {
        method: "post",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        }
    })

    return response.json();
}


export async function viewEducations(){
    const response = await fetch(`${URL}/educations.php`);
    return response.json();
}

export async function viewWorkplaces(){
    const response = await fetch(`${URL}/workplaces.php`);  
    return response.json();
}

export async function viewProjects(profileId) {
    const response = await fetch(`${URL}/projects.php${(profileId)?'?profileId=' + profileId :''}`);
    return response.json();
}

export async function viewProject(id, profileId) {
    const response = await fetch(`${URL}/projects/details.php?id=${id}&profileId=${profileId}`);
    return response.json();
}

export async function viewLastProject(profileId, superFeatured) {
    const response = await fetch(`${URL}/projects/details.php?superFeatured=${superFeatured}&${(profileId)?'profileId=' + profileId :''}`);
    return response.json();
}

export async function viewPosts(profileId) {
    const response = await fetch(`${URL}/blog/posts.php${(profileId)? '?profileId=' + profileId : ''}`);
    return response.json();
}

export async function viewPost(id, profileId) {
    const response = await fetch(`${URL}/blog/details.php?id=${id}${(profileId)?'&profileId=' + profileId :''}`);
    return response.json();
}

export async function viewSimilarProjects(profileId, company) {

    const response = await fetch(`${URL}/projects/similarproject.php?profileId=${profileId}&company=${company}`);
    return response.json();
}

export async function httpLogin(data) {
    const response = await fetch(`${URL}/login.php`, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    });

    return response.json();
}

export async function httpPassword(data) {
    const response = await fetch(`${URL}/settings/password.php`, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "X-Token" : localStorage.getItem("token")
        }
    });

    return response.json();
}

export async function httpRegister(data) {
    const response = await fetch(`${URL}/register.php`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    });

    return response.json();
}

export async function viewContact(profileId) {
    const response = await fetch(`${URL}/contacts.php?${(profileId)?"profileId=" + profileId:''}`);
    return response.json();
}

export async function viewNotice(profileId) {
    const response = await fetch(`${URL}/notices.php?${(profileId)?"profileId=" + profileId:''}`);
    return response.json();
}

export async function httpUpdateAccountSettings(data) {

    const {token} = JSON.parse(localStorage.getItem("token"));

    const response = await fetch(`${URL}/profiles.php`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "X-Token" : token
        }
    });

    return response.json();
}

export  async function httpUpdatePassword(data) {

    const {token} = JSON.parse(localStorage.getItem("token"));

    const response = await fetch(`${URL}/settings/password.php`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "X-Token" : token
        }
    });

    return response.json();
}

export async function viewMainCores(profileId) {
    const response = await fetch(`${URL}/maincores.php?${(profileId)?"profileId=" + profileId:''}`);
    return response.json();
}

export async function viewAchievement(profileId) {
    const response = await fetch(`${URL}/achievements.php?${(profileId)?"profileId=" + profileId:''}`);
    return response.json();
}

export async function getVideos(profileId) {
    const response = await fetch(`${URL}/videos.php?${(profileId)?"profileId=" + profileId:''}`);
    return response.json();
}

export async function viewUsers() {
    const response = await fetch(`${URL}/users.php`);
    return response.json();
}