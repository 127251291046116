import { useEffect, useState } from "react";
import { viewLastProject } from "../../../util/api";
import { Loading } from "../../layout/Loading";
import { useParams } from "react-router-dom";

const LastProject = ({profileId}) => {

  const [ project, setProject ] = useState({});
  const [ url, setUrl ] = useState("");
  //const { profileId } = useParams();
  


  useEffect(() => {    

    const lastProject = async (profileId, superFeatured) => {

      const data = await viewLastProject(profileId, superFeatured);
 
      if (data)
        if (profileId) {
         setUrl("/u/portfolio/" + data.profileId + "/" + data._id);
        } else {
         setUrl("/portfolio/" + data._id + "?profileId=" + data.profileId);
        }
      

      setProject(values => (data));
    }

    lastProject(profileId, 1);

  }, [profileId]);



  return (<div className={(project == null)? "card d-none": "card"}>

      <div className="border-bottom">
        <h6 className="card-title mb-0 px-4 py-2"> <i className="bi bi-heart-fill text-danger pe-1"></i>Last Projects</h6>

      </div>

      <div className="card-header border-0 pb-0">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">

            <div className="avatar me-2">
              <a href={url}> <img className="avatar-img rounded-circle" src={project?.thumb} alt="" /> </a>
            </div>

            <div>
              <h6 className="card-title mb-0"> <a href={url}>{project?.title}</a></h6>
              {/**<p className="mb-0 small">9 January at 23:29</p> */}
            </div>
          </div>


        </div>

      </div>

      <div className="card-body pb-0">
        {(project?.summary) ? <p>{project?.summary}</p> : <Loading />}
      </div>

      <div className="card-footer py-3">

        <ul className="nav nav-fill nav-stack small">
          <li className="nav-item">
            <a className="nav-link mb-0" href={url}> <i className="bi bi-eye pe-1"></i>View</a>
          </li>
        </ul>

      </div>

    </div>
  );
}

export default LastProject;