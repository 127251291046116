import { useEffect, useState } from "react";
import { viewPost } from "../../util/api";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingText } from "../layout/Loading";
import FeaturedPosts from "./FeaturedPosts";

export default function PostDetails(props) {

    const [post, setPost] = useState({});
    const { id, profileId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async (id, profileId) => {
            const data = await viewPost(id, profileId);

            if (!data) {
                navigate(-1);
            } else {
                props.setSiteTitle(data.title)
                setPost(values => (data));
            }
        }

        loadData(id, profileId);
        
    }, [id, navigate, props, profileId]);

    const handleHistroy = () => { navigate(-1); } 

    return (<main>
        <div className="container">
            <div className="row g-4"> 

                <div className="col-lg-8 mx-auto">
                    <div className="vstack gap-4">

                        <div className="card card-body">
                            <img className="rounded" src={post.coverImage} alt="" />
                            <div className="mt-4">

                                {/**<a href="#" className="badge bg-danger bg-opacity-10 text-danger mb-2 fw-bold">Lifestyle</a> */}

                                <h1 className="mb-2 h2">{post.title}</h1>
                                <ul className="nav nav-stack gap-3 align-items-center">
                                    <li className="nav-item">
                                        <div className="nav-link">
                                            by <a href="!#" className="text-reset btn-link">Rimsan</a>
                                        </div>
                                    </li>
                                    <li className="nav-item"> <i className="bi bi-calendar-date pe-1"></i>Nov 15, 2022</li>
                                    <li className="nav-item"> <i className="bi bi-clock pe-1"></i>5 min read</li>
                                </ul>

                                <a className="btn btn-primary position-fixed end-0 bottom-0 me-5 mb-5 btn-back" href="#!" onClick={handleHistroy}> <i className="fa-solid fa-angle-left"></i> Back</a>
	


                                <div className="row mb-4 mt-4" dangerouslySetInnerHTML={{ __html: post.content || LoadingText }}>

                                </div>




                            </div>
                        </div>

                        <FeaturedPosts />
                    </div>
                </div>
            </div>
        </div>

    </main>);
}