export const Loading = function () {
    return <div className="d-grid text-center p-3">
        <div className="load-icon">
            <div className="spinner-grow spinner-grow-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
}

export const LoadingWithoutSpace = ({text}) => {
    return <div className="load-icon">
    <div className="spinner-grow spinner-grow-sm" role="status">
       <span className="visually-hidden">Loading...</span>
    </div> 
</div>
}

export const LoadingText = `<div class="d-grid text-center p-3">        
            <div class="load-icon">
                <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>        
    </div>`;

