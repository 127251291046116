import { useState, useEffect } from "react";
import { viewPosts } from "../../util/api";
import { Loading } from "../layout/Loading";
import { useParams } from "react-router-dom";

export default function FeaturedPosts() {

    const [posts, setPosts] = useState({});
    const {profileId} = useParams();

    useEffect(() => {

        const loadPosts = async (profileId) => {
            const data = await viewPosts(profileId);
            setPosts(values => (data));
        }

        loadPosts(profileId); 

    }, [profileId]); 

    return (<>

        <div className="card">

            <div className="card-header pb-0 border-0"> 
                <h5 className="card-title mb-0">Recent post</h5>
            </div>

            <div className="card-body">
                {posts?.contents?.map((post, index) => {
                    return <div key={index} className="mb-3">
                        <h6 className="mb-0">
                            <a href={(profileId)? '/u/blog/' + profileId + "/" + post._id : '/blog/' + post._id}>{post.title}</a></h6>
                        {/**<small>2hr</small> */}
                    </div>
                }) || <Loading />}

            
                   <a href={(profileId)? '/u/blog/' + profileId : '/blog/' }>View all posts</a> 
                
            </div>

        </div>

    </>);
}