import { useEffect, useState } from "react"
import { httpLogin } from "../../util/api";
import { LoadingWithoutSpace } from "../layout/Loading";
import { useNavigate } from "react-router-dom";
import GoogleButton from "./GoogleButton";

export default function Login(props) {

    const [inputs, setInputs] = useState({});
    const [loading, setLoading] = useState("Login");
    const [passwordType, setPasswordType] = useState({
        type: "password",
        icon: "fa-eye-slash"
    });

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(<LoadingWithoutSpace />);
        const login = await httpLogin(inputs);

        if (login) {

            if ('token' in login) {
                const token = login.token;

                if (token) {
                    setLoading(login.message);
                    localStorage.setItem("token", JSON.stringify(login));
                    navigate("/settings")
                } else {
                    setLoading("Login");
                    localStorage.removeItem("token");
                }
            }
        }
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleshowPassword = () => {
        if (passwordType.type == "password") {
            setPasswordType({type: "text", icon: "fa-eye"});
        } else {
            setPasswordType({type: "password", icon: "fa-eye-slash"});
        }        
    }

    useEffect(() => {
        props.setSiteTitle("Login");
    }, [props]);

    return <main>
        <div className="container">
            <div className="row justify-content-center align-items-center  py-4">

                <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">

                    <div className="card card-body text-center p-4 p-sm-5">

                        <h1 className="mb-2">Sign in</h1>
                        <p className="mb-3">Don't have an account?<a href="sign-up"> Click here to sign up</a></p>
                        <center> <p className="mb-md-0 mb-xs-4"><GoogleButton text="signin_with" /></p></center>

                        <form className="mt-sm-4" onSubmit={handleSubmit}>

                            <div className="mb-3 input-group-lg">
                                <input type="email" required className="form-control" placeholder="Enter email" name="username" onChange={(e) => handleChange(e)} />
                            </div>

                            <div className="mb-3 position-relative">

                                <div className="input-group input-group-lg">
                                    <input className="form-control fakepassword" required type={passwordType.type} id="psw-input" placeholder="Enter new password" name="password" onChange={(e) => handleChange(e)} />
                                    <span className="input-group-text p-0">
                                        <i className={"fakepasswordicon fa-solid cursor-pointer p-2 w-40px " + passwordType.icon} onClick={handleshowPassword}></i>
                                    </span>
                                </div>
                            </div>

                            <div className="mb-3 d-sm-flex justify-content-between">
                                <div>
                                    <input type="checkbox" className="form-check-input" id="rememberCheck" />
                                    <label className="form-check-label" htmlFor="rememberCheck"> &nbsp; Remember me?</label>
                                </div>
                                {/**<a href="forgot-password">Forgot password?</a> */}
                            </div>

                            <div className="d-grid">
                                <button type="submit" className="btn btn-lg btn-primary">{loading}</button>

                            </div>



                        </form>

                    </div>

                </div>
            </div>
        </div>
    </main>
}