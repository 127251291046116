import { useEffect, useState } from "react"
import { viewProject } from "../../../util/api";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Loading, LoadingText } from "../../layout/Loading";
import SimilarProject from "./SimilarProject";
import FeaturedPosts from "../../blog/FeaturedPosts";

export default function PortfolioDetails(props) {

    const [portfolio, setPortfolio] = useState({});
    let { id, profileId } = useParams(); 
    const [query] = useSearchParams(); 

    if (!profileId) {
        profileId = query.get('profileId');
    }

    const navigate = useNavigate();

    useEffect(() => {
        const loadPortfolio = async (id, profileId) => {
            const portfol = await viewProject(id, profileId);

            if (!portfol) {
                navigate(-1);
            } else {
                props.setSiteTitle(portfol.title);
                setPortfolio(values => (portfol));
            }
        }
      

        loadPortfolio(id, profileId);
    }, [id, navigate, profileId, props]);

    console.debug("hhh")

    const handleHistroy = () => {
        navigate(-1)
    }

    return <> <section className="py-4 position-relative" style={{ backgroundImage: `url(${portfolio.coverImage})`, backgroundRepeat: 'repeat', backgroundSize: 'cover', backgroundPosition: 'top left' }}>
        <div className="bg-overlay bg-dark opacity-8"></div>
        <div className="container">
            <div className="py-5">
                <div className="row position-relative">
                    <div className="col-xl-8 col-lg-10 mx-auto pt-sm-5 text-center">

                        <h1 className="text-white">{portfolio.title || "Checking his works..."}</h1>


                        <ul className="nav nav-divider justify-content-center text-white pt-2 small mb-4">
                            {portfolio.tools?.map((tool, index) => {
                                return <li key={index} >
                                    <span className="badge p-2 bg-success bg-opacity-10 text-white mb-2 fw-bold mx-1">{tool}</span>
                                </li>
                            }) || ""}
                        </ul>

                        {(portfolio.thumb) ? <div className="avatar avatar-lg mt-2">
                            <img className="avatar-img rounded-circle" src={portfolio.thumb} alt="avatar" />
                        </div> : <Loading />}


                        <p className="mt-3 "><a className=" text-secondary text-white" href="#!"> <i className="bi bi-briefcase pe-1"></i> {portfolio.company}</a></p>

                    </div>
                </div>
            </div>
        </div>
    </section>
        <section className="py-5">
            <div className="container">
                <div className="row g-4">

                    <div className="col-lg-8 col-md-8">
                        <div className="card">
                            <div className="card-header d-sm-flex justify-content-between border-0 pb-0">
                                <h4 className="card-title">Overview</h4>
                                <a className="btn btn-primary position-fixed end-0 bottom-0 me-5 mb-5 btn-back" href="#!" onClick={handleHistroy}> <i className="fa-solid fa-angle-left"></i> Back</a>
                            </div>

                            <div className="card-body">
                                <div className="row g-4">
                                    <div className="col-12">
                                        <p>{portfolio?.summary}</p>
                                        <div dangerouslySetInnerHTML={{ __html: portfolio?.content || LoadingText }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4">
                
                        <div className="card mb-4">
                            <div className="card-header d-sm-flex align-items-center justify-content-between border-0 pb-0">
                                <h5 className="card-title mb-sm-0">Similar Projects</h5>
                                <a className="btn btn-primary-soft btn-sm" href={(profileId)?"/u/portfolio/" + profileId: "/portfolio"}> All Projects</a>
                            </div>
                            {(portfolio.company) ? <SimilarProject company={portfolio.company} profileId={portfolio.profileId} /> : <Loading />}
                        </div>

                        <FeaturedPosts />

                    </div>
                </div>
            </div>
        </section>
    </>

}