
import ViewCover from "../cover/ViewCover";

import ViewThreeValue from "../threevalue/ViewThreeValue";
import ViewSkill from "../skill/ViewSkill";

import ViewFeaturedProjects from "../project/FeaturedProjects";
import LastProject from "../project/LastProject";
import FeaturedPosts from "../../blog/FeaturedPosts";
import { useParams } from "react-router-dom";
import Intro from "./Intro";
import Achievement from "../cover/Achievement";
import { useSelector } from "react-redux";

export default function Welcome() {

  const { profileId } = useParams();
  //const [profile, setProfile] = useState({});

  const profile = useSelector(state => state.profile.contents);

  return <main>

    {(profileId) ? "" : <Intro />} 

    <div className="container ">
      <div className={(profileId) ? "row g-4" : "row g-4 d-none"}>
        {/*<div className="col-lg-3">
      <div class="col-sm-6 col-lg-12">
        <News />
      </div>
    </div>*/}

        {/**<section className="py-4 py-sm-5">
        <div className="container">
          <div className="card card-body bg-light shadow-none border-0 p-4 p-sm-5 text-center">
            <div className="col-lg-8 mx-auto">

              <h2 className="h1">Create your resume profile</h2>
              <p className="lead mb-4">Frequently sufficient to be unaffected. The furnished she concluded depending procuring concealed. </p>
          

              <div className="d-sm-flex justify-content-center mt-3">

                <button className="btn btn-primary">Sign up free</button>
                <div className="mt-2 mt-sm-0 ms-sm-3">

                  <div className="hstack justify-content-center justify-content-sm-start gap-1">
                    <div><i className="fa-solid fa-star text-warning"></i></div>
                    <div><i className="fa-solid fa-star text-warning"></i></div>
                    <div><i className="fa-solid fa-star text-warning"></i></div>
                    <div><i className="fa-solid fa-star text-warning"></i></div>
                    <div><i className="fa-solid fa-star-half-stroke text-warning"></i></div>
                  </div>

                  <i>"I can't believe it's free!"</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


        <div className="col-md-8 col-lg-6 vstack gap-4 ">

          {/**<EditProfile /> */}




          <ViewCover profile={profile.profile} />
          <Achievement />

          {(profile.whatIDo) ? <ViewThreeValue whatIDo={profile.whatIDo} /> : ""}

          {/**<ViewEducation />
          <ViewWorkplace /> */}
          <ViewSkill whatILove={profile.whatILove} />
          <ViewFeaturedProjects profileId={profileId} />

          {/**<RegistredAccount /> */}

        </div>

        <div className="col-lg-3">
          <div className="row g-4">

            {/** <div>
              <Nav />
            </div> */}



            <div>
              <LastProject profileId={profileId} />
            </div>

            <div>
              <FeaturedPosts />
            </div>



          </div>
        </div>


      </div>

    </div>
  </main>
}