import { useEffect, useState } from "react";
import { viewUsers } from "../../../util/api";
import { useSelector } from "react-redux";

export default function Users() {

    const users = useSelector(state => state.users);

    return (<ul className="avatar-group list-unstyled align-items-center mx-5" style={{justifyContent: "center"}}>

        {
            users.map((value, index) => {
                return <li key={index} className="avatar avatar-lg">
                    <a href={"/u/about/" + value._id}><img className="avatar-img rounded-circle" src={value.profilePicture} alt="avatar" /></a>
                </li>
            })

        }

        <li className="ms-4 d-none">
            <small> 120 people created</small>
        </li>

    </ul>);
}