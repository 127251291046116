import { GoogleLogin } from "@react-oauth/google";
import { httpRegister } from "../../util/api";
import { useNavigate } from "react-router-dom";



export default function GoogleLoginButton({ text, width, type}) {

    const navigate = useNavigate();

    return (<GoogleLogin

            onSuccess={async credentialResponse => {

                let { credential } = credentialResponse;
             
                const req = await httpRegister({ 
                    jwt: credential,
                    google: 1 
                });

                if (req.jwt) {
                    const data = {token: req.jwt, message: "success", profileId: req.profileId }
                    localStorage.setItem("token", JSON.stringify(data));
                    navigate("/settings");
                }
                
            }}

            onError={() => {
                console.log('Login Failed');
            }}

            text={text}
            width={width || "100%"}
            theme="filled_blue"
            type={type || "standard"}
        
        />)
}