import { Loading } from "../../layout/Loading";

const ViewSkill = (props) => {

    const { whatILove } = props;
  
    return (
      <div className="card">
  
        <div className="card-header border-0 pb-0">
          <h5 className="card-title">{whatILove?.title}</h5>
          <p dangerouslySetInnerHTML={{ __html: whatILove?.description }}></p>
        </div>
  
  
        <div className="card-body">
          <div className="row g-4">
  
          {/**<div className="col-sm-12 col-lg-12 position-relative">
            <a className="btn btn-dashed rounded w-100" href="#!"> <i className="bi bi-plus-circle-dotted me-1"></i>Add new skill</a>
          </div> */}
          
            {whatILove?.contents.map((value, index) => {
  
              let skillName = value.skillName || "";
              if (skillName.length < 18) {
                skillName = skillName + " &nbsp;".repeat(16 - skillName.length)
              }
  
              return <div key={index} className="col-sm-6 col-lg-3">
                <div className="d-flex align-items-center position-relative">
                  <div className="avatar">
                    <img className="avatar-img" src={value.iconUrl} alt="" />
                  </div>
                  <div className="ms-2">
                    <h6 className="mb-1"> <span className="stretched-link"  dangerouslySetInnerHTML={{ __html: skillName }}></span></h6>
                    <div className="progress small mb-0 ">
                      <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: value.skillLevel + '%' }} aria-valuenow={value.skillLevel} aria-valuemin="0" aria-valuemax="100">{value.skillLevel}%</div>
                    </div>
                  </div>
                </div>
              </div>
            }) || <Loading />}
          </div>
          
        </div>
  
      </div>
    );
  }
  
  export default ViewSkill;