import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { viewNotice } from "../../../util/api";
import { Loading } from "../../layout/Loading";

export default function Notice() { 

    const {profileId} = useParams();
    const [notice, setNotice] = useState({});

    const [loading, setLoading] = useState(<Loading />);

    useEffect(() => {

        const loadNotice = async (profileId) => {
            const noticObj = await viewNotice(profileId);
            if (noticObj)
                setNotice(values => (noticObj));
            else    
                setLoading("")
        }

        loadNotice(profileId);

    }, [profileId]);

    return (notice?.contents)? <div className="alert alert-success alert-dismissible fade show mb-0" role="alert">
         <strong>Note:</strong> { notice?.contents }
    </div> : loading;
}