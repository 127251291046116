import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { viewContact } from "../../util/api";
import { LoadingText } from "../layout/Loading";

export default function ContactMe(props) {

    const { profileId } = useParams();
    const [contact, setContact] = useState({});
    const [txtContact] = useState("Contact");
    const [contactData, setContactData] = useState("");

    useEffect(() => {
        props.setSiteTitle("Contact");

        const loadContact = async (profileId) => {
            const conta = await viewContact(profileId);
            setContact(values => (conta))
        };

        loadContact(profileId);

    }, [profileId, props]);

    const navigate = useNavigate();
    const handleHistroy = () => { navigate(-1); }

    const hangdleContactSubmit = (e) => {
        e.preventDefault();
        console.log(contactData)
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setContactData(values => ({ ...values, [name]: value }));
    }

    return (<main>
        <div className="container">
            <div className="row">
                <div className="col-8">
                    <div className="card mb-4">
                        <div className="card-header border-0 pb-0">
                            <h1 className="h5 card-title">Interested in hiring me for your project or just want to say hi?</h1>
                            <a className="btn btn-primary position-fixed end-0 bottom-0 me-5 mb-5 btn-back" href="#!" onClick={handleHistroy}> <i className="fa-solid fa-angle-left"></i> Back</a>

                        </div>
                        {(contact != null)?<div className="card-body" dangerouslySetInnerHTML={{ __html: contact?.contents || LoadingText }}></div>:<div className="card-body">User has not updated the contact details. Please message !</div>}
                        


                    </div>
                </div>
                <div className="col-4">
                    <div className="card">

                        <div className="card-header border-0 pb-0">
                            <h5 className="h5 card-title">Contact</h5>
                            <p className="mb-0"></p>
                        </div>

                        <div className="card-body">

                            <form className="row g-3" onSubmit={hangdleContactSubmit}>

                                <div className="col-12">
                                    <textarea className="form-control" rows="4" placeholder="Message here" name="contact" onChange={handleChange}></textarea>
                                </div>

                                <div className="col-12 text-end">
                                    <button type="submit" className="btn btn-primary mb-0">{txtContact}</button>
                                </div>
                            </form>
 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>);
}