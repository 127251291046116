export default function Post({ post, profileId }) {

    let url ="";

    if (profileId) {
        url = '/u/blog/' + profileId + '/' + post._id
    } else {
        url = '/blog/' + post._id;
    }

    return (<>
        <div className="card bg-transparent border-0">
            <div className="row g-3">
                <div className="col-4"> 

                    <img className="rounded" src={post.coverImage} alt="" />
                </div>
                <div className="col-8">

                    {/**<a href="#" className="badge bg-danger bg-opacity-10 text-danger mb-2 fw-bold">Lifestyle</a> */}
                    <h5><a href={url} className="btn-link stretched-link text-reset fw-bold">{post.title}</a></h5>
                    <div className="d-none d-sm-inline-block">
                        <p className="mb-2">{post.description}</p>

                        {/**<a className="small text-secondary" href="#!"> <i className="bi bi-calendar-date pe-1"></i> Jan 22, 2022</a> */}
                    </div>
                </div>
            </div>
        </div>
        <hr className="my-4" /> 
    </>);
}