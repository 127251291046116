import { useState, useEffect } from "react";
import { viewProjects } from "../../../util/api";
import Project from "./Project";
import {Loading} from "../../layout/Loading";
import { useNavigate, useParams } from "react-router-dom";

export default function AllProjects() {

  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const { profileId } = useParams();

  useEffect(() => {
    const loadProjects = async () => {
      const project = await viewProjects(profileId);
      setProjects(values => (project));
    }
    loadProjects(); 
  }, []);

  const handleHistroy = () => {
    navigate(-1)
  }

  return <main>
    <div className="container">
      <div className="row g-4">

      

        <div className="card ">
          <div className="card-header border-0 pb-0" >
            <h1 className="h4 mb-2">All Projects</h1>
            <p>Welcome to my online portfolio. Some of my projects that i worked.</p>
            <a className="btn btn-primary position-fixed end-0 bottom-0 me-5 mb-5 btn-back" href="#!" onClick={handleHistroy}> <i className="fa-solid fa-angle-left"></i> Back</a>
	
          </div>
          <div className="card-body">
            <div className="row g-4">
              {projects?.contents?.map((data, index) => {
                return <Project project={data} key={index} />
              }) || <Loading /> }

            </div>
          </div>
        </div>

      </div>
    </div>
  </main>
}