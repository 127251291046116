import { createSlice } from "@reduxjs/toolkit";

const initialState = async () => {
    const result = await fetch("https://rimsan.me/endpoint/users.php");
    return result.json();
}

const userSlice = createSlice({
    name: "users",
    initialState: await initialState(),
    reducers: {}
})


export default userSlice.reducer;