import { useEffect, useState } from "react"
import { httpUpdatePassword } from "../../../util/api";
import { LoadingWithoutSpace } from "../../layout/Loading";



export default function Password() {

    const [password, setPassword] = useState({
        password: '',
        confirmPassword: ''
    });

    const [txtPassword, setTxtPassword] = useState("Update password");

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPassword(values => ({...values, [name]: value }));
    }

    const hangdleSubmit = async (e) => {
        e.preventDefault();
        setTxtPassword(<LoadingWithoutSpace />);

        if (password.password === password.confirmPassword && ((password.password !=="") && (password.confirmPassword!=="") )) {
            
           const result =  await httpUpdatePassword(password);

           if (result.message == 'success') {
            alert("Password successfully updated!");
            setTxtPassword("Update password");
           }
        } else {
            alert("Missmatch password!");
            setTxtPassword("Update password");
        }
    }



    return <div className="card">

        <div className="card-header border-0 pb-0">
            <h5 className="card-title">Change your password</h5>
            <p className="mb-0"></p>
        </div>

        <div className="card-body">

            <form className="row g-3" onSubmit={hangdleSubmit}>

                <div className="col-12 d-none">
                    <label className="form-label">Current password</label>
                    <input type="text" className="form-control" placeholder="" />
                </div>

                <div className="col-12">
                    <label className="form-label">New password</label>

                    <div className="input-group">
                        <input className="form-control fakepassword" type="password" id="psw-input" placeholder="Enter new password" name="password" onChange={handleChange} defaultValue={password.password} />
                        <span className="input-group-text p-0">
                            <i className="fakepasswordicon fa-solid fa-eye-slash cursor-pointer p-2 w-40px"></i>
                        </span>
                    </div>

                </div>

                <div className="col-12">
                    <label className="form-label">Confirm password</label>
                    <input type="password" className="form-control" placeholder="Enter confirm password" name="confirmPassword" onChange={handleChange} defaultValue={password.confirmPassword} />
                </div>

                <div className="col-12 text-end">
                    <button type="submit" className="btn btn-primary mb-0">{txtPassword}</button>
                </div>
            </form>

        </div>
    </div>
}