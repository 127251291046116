const Footer = () => {


  return (<>

    <footer className=" bg-mode">

      <hr className="mb-0" style={{margin: 0}} />
      <div className="bg- light py-3 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">

              <ul className="nav justify-content-center justify-content-lg-start lh-1">
              <li className="nav-item">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/terms-of-Service">Terms of Service</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/privacy-policy">Privacy policy</a>
                </li>
              </ul>

            </div>
            <div className="col-lg-6">

              <p className="text-center text-lg-end mb-0">©2024 <a className="text-body"  href="https://www.rimsan.me"> Rimsan.me </a>All rights reserved.</p>

            </div>
          </div>
        </div>
      </div>
    </footer>
  </>)

}

export default Footer;